import { addBookingsPage } from '../pages-actions';
import { MigrationFailReason, PageId } from '../../constants';
import {
  getAllSiteComponents,
  getFullComponentStructure,
  updateComponentStyle,
  updateControllerConfiguration,
} from '../editor-sdk-actions';
import { ComponentStructure, ComponentType } from '../../migration/domain';
import { getNewCalendarPageStyles } from './styles-transpiler';
import { getNewCalendarPageData } from './settings-data-transpiler';
import { closeBar, openBar, setBarStep } from '../progressbar-utils';

export async function handleCalendarPageMigration(
  sdk,
  appToken,
  instance,
  translations,
  enableProgressBar = true,
) {
  if (enableProgressBar) {
    await openBar(
      sdk,
      appToken,
      translations,
      'bookings-migration.progress-bar.title',
      3,
    );
    await setBarStep(
      sdk,
      appToken,
      translations,
      'calendar-page-migration.progress-bar.step-1',
      1,
    );
  }
  try {
    await addBookingsPage(PageId.BOOKINGS_CALENDAR_PAGE, sdk, appToken);
  } catch {
    enableProgressBar && closeBar(sdk, appToken);
    throw MigrationFailReason.PAGES;
  }
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return Promise.all(
      allComponents.map((componentRef) =>
        getFullComponentStructure(sdk, appToken, componentRef),
      ),
    );
  })();
  const bookingsCheckoutWidgetId = '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  const calendarPageWidgetId = '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  const compStructuresCheckout: ComponentStructure = siteStructure.find(
    (comp) =>
      comp?.type === ComponentType.COMPONENT &&
      comp?.data?.widgetId === bookingsCheckoutWidgetId,
  );
  const compStructuresCalendarPage: ComponentStructure = siteStructure.find(
    (comp) =>
      comp?.type === ComponentType.COMPONENT &&
      comp?.data?.widgetId === calendarPageWidgetId,
  );

  if (!compStructuresCheckout) {
    throw new Error('compStructuresCheckout not found in calendar-page-migration');
  }

  if (compStructuresCheckout.style?.style?.properties) {
    console.log('Old Calendar Page Properties:');
    console.log(compStructuresCheckout);
    console.table(compStructuresCheckout.style.style.properties);
    console.log('New Calendar Page structure: (Before Change)');
    console.log(compStructuresCalendarPage);
    console.table(compStructuresCalendarPage?.style?.style?.properties);
    const newCalendarPageData = getNewCalendarPageData(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Layouts to write:');
    console.table(newCalendarPageData);
    enableProgressBar &&
      (await setBarStep(
        sdk,
        appToken,
        translations,
        'calendar-page-migration.progress-bar.step-2',
        2,
      ));
    if (newCalendarPageData) {
      try {
        if (!compStructuresCalendarPage) {
          throw new Error('compStructuresCalendarPage not found for action newCalendarPageData - errorCode: newCalendarPageData_compStructuresCalendarPage_not_found');
        }

        await updateControllerConfiguration(
          sdk,
          appToken,
          compStructuresCalendarPage.id,
          newCalendarPageData,
        );
      } catch {
        enableProgressBar && closeBar(sdk, appToken);
        throw MigrationFailReason.DATA;
      }
    }
    const newServicePageStyles = getNewCalendarPageStyles(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Styles to write:');
    console.table(newServicePageStyles);
    enableProgressBar &&
      (await setBarStep(
        sdk,
        appToken,
        translations,
        'calendar-page-migration.progress-bar.step-3',
        3,
      ));
    if (newServicePageStyles) {
      try {
        if (!compStructuresCalendarPage) {
          throw new Error('compStructuresCalendarPage not found for action newServicePageStyles - errorCode: newServicePageStyles_compStructuresCalendarPage_not_found');
        }

        await updateComponentStyle(
          sdk,
          appToken,
          compStructuresCalendarPage.id,
          newServicePageStyles,
        );
      } catch {
        enableProgressBar && closeBar(sdk, appToken);
        throw MigrationFailReason.STYLES;
      }
    }
  } else {
    console.log('Old calendar page is plain, no styles to migrate.');
  }

  return enableProgressBar && closeBar(sdk, appToken);
}

export async function migrate(
  sdk,
  appToken,
  instance,
  translations,
  onNextStep,
) {
  onNextStep(1);
  try {
    await addBookingsPage(PageId.BOOKINGS_CALENDAR_PAGE, sdk, appToken);
  } catch {
    throw MigrationFailReason.PAGES;
  }
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return Promise.all(
      allComponents.map((componentRef) =>
        getFullComponentStructure(sdk, appToken, componentRef),
      ),
    );
  })();
  const bookingsCheckoutWidgetId = '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  const calendarPageWidgetId = '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  const compStructuresCheckout: ComponentStructure = siteStructure.find(
    (comp) =>
      comp?.type === ComponentType.COMPONENT &&
      comp?.data?.widgetId === bookingsCheckoutWidgetId,
  );
  const compStructuresCalendarPage: ComponentStructure = siteStructure.find(
    (comp) =>
      comp?.type === ComponentType.COMPONENT &&
      comp?.data?.widgetId === calendarPageWidgetId,
  );
  if (compStructuresCheckout.style?.style?.properties) {
    if (!compStructuresCheckout) {

    }

    console.log('Old Calendar Page Properties:');
    console.log(compStructuresCheckout);
    console.table(compStructuresCheckout.style.style.properties);
    console.log('New Calendar Page structure: (Before Change)');
    console.log(compStructuresCalendarPage);
    console.table(compStructuresCalendarPage.style?.style?.properties);
    const newCalendarPageData = getNewCalendarPageData(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Layouts to write:');
    console.table(newCalendarPageData);
    onNextStep(2);
    if (newCalendarPageData) {
      try {
        await updateControllerConfiguration(
          sdk,
          appToken,
          compStructuresCalendarPage.id,
          newCalendarPageData,
        );
      } catch {
        throw MigrationFailReason.DATA;
      }
    }
    const newServicePageStyles = getNewCalendarPageStyles(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Styles to write:');
    console.table(newServicePageStyles);
    onNextStep(3);

    if (newServicePageStyles) {
      try {
        await updateComponentStyle(
          sdk,
          appToken,
          compStructuresCalendarPage.id,
          newServicePageStyles,
        );
      } catch {
        throw MigrationFailReason.STYLES;
      }
    }
  } else {
    console.log('Old calendar page is plain, no styles to migrate.');
  }
}
