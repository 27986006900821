import {
  EditorSDK,
  PlatformEvent,
} from '@wix/platform-editor-sdk';
import {IRevisionChangedCallback} from '../types';

let revisionChangedCallbacks: IRevisionChangedCallback[] = [];

export const onHistoryEvent = (event: PlatformEvent) => {
  if (event.eventType === 'undo' || event.eventType === 'redo') {
    revisionChangedCallbacks.forEach((callback) => callback());
  }
};

export const addHistoryEventsHandlers = async (editorSDK: EditorSDK) => {
  await editorSDK.document.application.registerToCustomEvents('token', {
    eventTypes: ['undo', 'redo'],
  });
  await editorSDK.editor.setAppAPI('token', {
    subscribeToRevisionChanged: (callback: IRevisionChangedCallback) => {
      revisionChangedCallbacks.push(callback);
    },
    unsubscribeFromRevisionChanged: (callback: IRevisionChangedCallback) => {
      revisionChangedCallbacks = revisionChangedCallbacks.filter(
        (registeredCallback) => registeredCallback !== callback,
      );
    },
  });
};
