import { HandleActionPayload, PageId } from '../../constants';
import { ComponentStructure } from '../../migration/domain';
import { getNewCalendarPageData } from '../calendar-page-migration/settings-data-transpiler';
import { getNewCalendarPageStyles } from '../calendar-page-migration/styles-transpiler';
import { BasePageMigrator } from './base-page-migrator';
import { isBookingCalendarInstalled } from '../editor-sdk-actions';

export class CalendarPageMigrator extends BasePageMigrator {
  constructor(protected editorSDK, protected appToken, protected instance, protected handleActionPayload: HandleActionPayload) {
    super(editorSDK, appToken, instance, handleActionPayload);
  }

  public async shouldMigrate(): Promise<boolean> {
    const bookingCalendarInstalled = await isBookingCalendarInstalled(this.editorSDK);

    return !bookingCalendarInstalled;
  }

  public async execute() {
    await this.addBookingsPage(PageId.BOOKINGS_CALENDAR_PAGE);

    const siteStructure = await this.getSiteStructure();
    const checkoutStructure: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
    );
    const calendarStructure: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.widgetId,
    );

    if (!checkoutStructure) {
      throw new Error('Bookings Checkout Page not exist - errorCode: f1dfeb4e-3408-4521-97cb-95a309672edb');
    }

    if (checkoutStructure.style?.style?.properties) {
      console.log('Old Calendar Page Properties:');
      console.log(checkoutStructure);
      console.table(checkoutStructure.style.style.properties);
      console.log('New Calendar Page structure: (Before Change)');
      console.log(calendarStructure);
      console.table(calendarStructure.style?.style?.properties);
      const newCalendarPageData = getNewCalendarPageData(
        checkoutStructure.style.style.properties,
      );
      console.log('Layouts to write:');
      console.table(newCalendarPageData);
      if (newCalendarPageData) {
        await this.updateComponentSettings(
          calendarStructure.id,
          newCalendarPageData,
        );
      }
      const newCalendarPageStyles = getNewCalendarPageStyles(
        checkoutStructure.style.style.properties,
      );
      console.log('Styles to write:');
      console.table(newCalendarPageStyles);

      if (newCalendarPageStyles) {
        await this.updateComponentStyles(
          calendarStructure.id,
          newCalendarPageStyles,
        );
      }
    } else {
      console.log('Old calendar page is plain, no styles to migrate.');
    }

    await this.createPageState(
      PageId.BOOKINGS_CALENDAR_PAGE,
      'bookingCalendar',
    );
  }

  protected get widgetId() {
    return '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  }

  public get widgetName() {
    return 'CalendarPageMigrator';
  }
}
