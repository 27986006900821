import { HttpClient, HttpResponse } from '@wix/http-client';
import {
  getSiteRolloutStatus,
  markEcomCompatible,
  markMigratedSiteEcomCompatible,
  canInstallEcomPages,
  canMigrate as canMigrateEcomPages
} from '@wix/ambassador-bookings-v1-site-rollout-status/http';
import {
  MarkMigratedSiteEcomCompatibleResponse,
  MarkEcomCompatibleResponse,
  GetSiteRolloutStatusResponse,
  CanMigrateResponse
} from '@wix/ambassador-bookings-v1-site-rollout-status/types';

export class EditorScriptApi {
  constructor(private httpClient: HttpClient) {}

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  sleepUntil(condition: any) {
    return new Promise(resolve => {
      const checkEditorReadyDone = () => {
        if(condition()) {
          resolve(true);
        } else {
          setTimeout(checkEditorReadyDone, 500);
        }
      }

      checkEditorReadyDone();
    });
  }

  async fetchWithRetry<T>(fetchCall: any, retries = 3, delay = 1000): Promise<T> {
    for (let i = 0; i < retries; i++) {
      try {
        await this.sleep(delay * i);
        return await fetchCall();
      } catch (e: any) {
        if (i == retries - 1) {
          throw e;
        }
      }
    }
  }

  async getIsBookingsOnEcom(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.fetchWithRetry<HttpResponse<GetSiteRolloutStatusResponse>>(() => this.httpClient.request<GetSiteRolloutStatusResponse>(
        getSiteRolloutStatus({})),
      );
      return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
    } catch {
      return false;
    }
  }

  async getIsDataMigrationFailed(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.fetchWithRetry<HttpResponse<GetSiteRolloutStatusResponse>>(() => this.httpClient.request<GetSiteRolloutStatusResponse>(
        getSiteRolloutStatus({})),
      );
      return siteRolloutStatusResponse.siteRolloutStatus.isDataMigrationFailed;
    } catch {
      return false;
    }
  }

  async migrateMediaGallery() {
    await this.fetchWithRetry(() => this.httpClient.post(
      '/internal/services-server/media-migration',
      { maxRedirects: 10 },
    ));
  }

  async setEcomCompatible(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.fetchWithRetry<HttpResponse<MarkEcomCompatibleResponse>>(() => this.httpClient.request(markEcomCompatible({})));

      return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
    } catch {
      return false;
    }
  }

  async setMigratedSiteEcomCompatible(): Promise<boolean> {
    const { data: siteMigratedStatusResponse } = await this.fetchWithRetry<HttpResponse<MarkMigratedSiteEcomCompatibleResponse>>(() => this.httpClient.request(markMigratedSiteEcomCompatible({})));

    return siteMigratedStatusResponse.siteRolloutStatus.isBookingPlatformReady;
  }

  async canInstallEcom(): Promise<boolean> {
    try {
      const {
        data: { canInstall },
      } = await this.fetchWithRetry(() => this.httpClient.request(canInstallEcomPages({})));

      return canInstall;
    } catch {
      return false;
    }
  }

  async canMigrateEcom(): Promise<boolean> {
    try {
      const {
        data: { canMigrate },
      } = await this.fetchWithRetry<HttpResponse<CanMigrateResponse>>(() => this.httpClient.request(canMigrateEcomPages({})));

      return canMigrate;
    } catch {
      return false;
    }
  }

  async shouldMigrateEcom(skipCanInstall: boolean, checkCanMigrate: boolean): Promise<boolean> {
    try {
      if (checkCanMigrate) {
        const canMigrate = await this.canMigrateEcom();
        if (!canMigrate) {
          return false;
        }
      }

      if (skipCanInstall) { // TODO: should be removed after migrate wix employees
        const isDataMigrationFailed = await this.getIsDataMigrationFailed();
        return !isDataMigrationFailed;
      } else {
        const canInstall = await this.canInstallEcom();
        return canInstall;
      }
    } catch (e) {
      return false;
    }
  }
}
